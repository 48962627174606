import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';
import {
  Button,
  Menu,
  MenuItem,
  Checkbox,
  ListItemText,
  useMediaQuery,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import "../BuyNavbar.css"
const CustomDropdown = ({ label, options, selectedValues, onSelect,queryoptions }) => {
  const isScreenSmall = useMediaQuery('(max-width:1150px)');
  const [searchParams, setSearchParams] = useSearchParams();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (option) => {
    const params = new URLSearchParams(searchParams);
    const currentIndex = selectedValues.indexOf(option);
    const newSelectedValues = [...selectedValues];
  
    if (currentIndex === -1) {
      // Option not selected, so add it to the selected values
      newSelectedValues.push(option);
      params.append(label, option);  // Add to URL params
    } else {
      // Option already selected, so remove it from selected values
      newSelectedValues.splice(currentIndex, 1);
  
      // Remove the specific option from the URL
      const allParams = params.getAll(label);
      params.delete(label);  // Delete all instances of 'label'
  
      // Re-append all values except the one we are removing
      allParams.forEach((value) => {
        if (value !== option) {
          params.append(label, value);
        }
      });
    }
  
    setSearchParams(params);  // Update the URL with new params
    onSelect(newSelectedValues);  // Call the onSelect handler with updated values
  };
  
  const customFontFamily = {
    fontFamily: '"Roboto", Helvetica, Arial, sans-serif',
  };

  return (
    <>
      <Button
        onClick={handleClick}
        variant="outlined"
        style={{
          padding: '4px 9px',
          fontSize: '16px',
          borderColor:selectedValues.length>0?"white" :'#000000DE',
          fontWeight: '300',
          color: selectedValues.length>0?"white" :'#000000DE',
          textTransform: "none",
          width: isScreenSmall ? '98%' : 'auto',
          backgroundColor:selectedValues.length>0?"#0384fb":"",
          display: "flex",
          justifyContent: "space-between",
          ...customFontFamily,
        }}
      >
        <span style={{ minWidth: "90px", textAlign: isScreenSmall ? "left" : "" }}>{label}</span> <FontAwesomeIcon style={{ marginLeft: '5px', color: selectedValues.length>0?"white" :"#000000DE" }} icon={faCaretDown} />
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        style={{ marginTop: '10px', ...customFontFamily }}
      >
        {options.map((option) => (
          <MenuItem
            key={option}
            onClick={() => handleMenuItemClick(option)}
            style={{ paddingTop: "0px", paddingBottom: "0px", ...customFontFamily, height: "35px", paddingLeft: "0px" }}
          >
            <Checkbox checked={selectedValues?.indexOf(option) > -1} />
            {/* {label === "Asset Type" && <div style={{ backgroundColor: option === "Bank Owned" ? "#0384FB" : option === "Foreclosure" ? "#8b0000" : "green" }} className='house-circle-icon' ></div>} */}
            <div className='more-filter-dropddown-below-text'><span>{option}</span></div>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

CustomDropdown.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedValues: PropTypes.arrayOf(PropTypes.string),
  onSelect: PropTypes.func.isRequired,
};

export default CustomDropdown;
