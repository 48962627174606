import React, { useEffect, useState } from 'react'
import PropertyCard from '../PropertyCard/PropertyCard'
import "./PropertySample.css"
import PropTypes from 'prop-types';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faFileExport, faPrint,faCaretDown } from '@fortawesome/free-solid-svg-icons';
function PropertySample({  filteredProducts }) {
  const products = filteredProducts || useSelector((state) => state.property.Properties);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  if (products.length===0) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress size={100} />
      </Box>
    )
  }
  return (
    <>
      {products ?
        <div className='property-sample'>
          <div className='property-location'>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d387199.51147032203!2d-74.30932647699741!3d40.69608170403378!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c2f9505bd01851%3A0xeb495aecf9816815!2sTiLT%20Museum!5e0!3m2!1sen!2sin!4v1718468874061!5m2!1sen!2sin"
              width="100%"
              height="600px"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
          <div className='property-cards' style={{ height: (products.length <= 2 && screenWidth >= 646) ? "455px" : "" }}>
            <div className="buy-navbar-extra-filters-container">
              <div className="buy-navbar-extra-filters-title-section">
                <h1>
                  Home and Real Estate Auctions in <span>All States  <FontAwesomeIcon className='buy-navbar-extra-filters-icons' icon={faCaretDown}/> </span>
                </h1>
                <p>
                  {products.length} Results, sorted by <span>Most Popular <FontAwesomeIcon className='buy-navbar-extra-filters-icons' icon={faCaretDown}/></span>
                </p>
              </div>

              <div className="buy-navbar-extra-filters-buttons">
                <button className="buy-navbar-extra-filters-special-promo-btn">
                  Special Promotions
                </button>
                <button className="buy-navbar-extra-filters-nearby-btn">
                  <FontAwesomeIcon icon={faPaperPlane} /> Nearby Properties
                </button>
                <button className="buy-navbar-extra-filters-export-btn">
                  <FontAwesomeIcon icon={faFileExport} /> Export
                </button>
                <button className="buy-navbar-extra-filters-print-btn">
                  <FontAwesomeIcon icon={faPrint} /> Print
                </button>
              </div>
            </div>
            {products.map((property, index) =>
              <PropertyCard key={index} Property={property} product={property} />
            )}
          </div>
        </div> : <div>Loading...</div>
      }
    </>
  )
}
export default PropertySample
