import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuildingColumns, faHouseChimney, faHouseFlag, faArrowRight, faAngleRight, faBars, faX } from '@fortawesome/free-solid-svg-icons';
import "./SmallScreenNavbar.css"
import ReoBank from "./assets/ReoBank.png";
import Market from "./assets/Market.png";
import Sales from "./assets/Sales.png"
import { Images } from '../../../constants/constant'
import { useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useFilterContext } from '../../../ContextProvider/Context';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { clearUser } from '../../../features/user/userSlice';
const SmallScreenNavbar = () => {
    const { setAssetType } = useFilterContext();
    const navigate = useNavigate();
    const [clickonMenuIcon, setClickonMenuIcon] = useState(false);
    const [hoveredOnIcon, setHoveredOnIcon] = useState({ 1: false, 2: false, 3: false });
    const [scrolled, setScrolled] = useState(false);
    const [BuyItemVisible, setBuyVisible] = useState(false);
    const [ResourcesItemVisible, setResourcesVisible] = useState(false);
    const [ComapanyVisible, setCompanyVisible] = useState(false);
    const [CalendarVisible, setCalendarVisible] = useState(false);
    const isScreenSmall = useMediaQuery('(max-width:400px)')
    const user = useSelector((state) => state.user.user);
    console.log(user);
    useEffect(() => {
        const handleScroll = () => {
            const isScrolled = window.scrollY > 50;
            setScrolled(isScrolled);
        };
        // Add the event listener
        window.addEventListener('scroll', handleScroll);

        // Remove event listener on cleanup
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);
    const handleClickonItem = (ClickedItem) => {
        if (ClickedItem === "Buy") { InvisibleAllItems(); setBuyVisible(true) }
        if (ClickedItem === "Calender") { InvisibleAllItems(); setCalendarVisible(true) }
        if (ClickedItem === "Resources") { InvisibleAllItems(); setResourcesVisible(true) }
        if (ClickedItem === "Company") { InvisibleAllItems(); setCompanyVisible(true) }
    }


    const InvisibleAllItems = () => {
        setBuyVisible(false)
        setCalendarVisible(false)
        setResourcesVisible(false)
        setCompanyVisible(false)
        setClickonMenuIcon(false);
    }
    const handleClickonMenuIcon = () => {
        if (clickonMenuIcon === true) {
            setBuyVisible(false)
            setCalendarVisible(false)
            setResourcesVisible(false)
            setCompanyVisible(false)
            setClickonMenuIcon(false)
        }
        else {
            setClickonMenuIcon(true);
        }
    }
    const handleClick = (asset) => {
        if (asset !== "explore-more") { setAssetType([asset]) } // Append asset to existing assetType array
        if (asset === "Bank Owned") { navigate("/Bank-Owned") }
        if (asset === "Short Sales") { navigate("/Short-Sales") }
        if (asset === "Foreclosure") { navigate("/Foreclosure") }
        if (asset === "explore-more") { navigate("/all") }
    };
    return (
        <nav className={scrolled ? 'nav-scrolled' : ''} id="my-smallscreen-navbar" >
            <div className="wrapper">
                <div className="logo">
                    <a href="/">
                        <img className='logo-small' src={scrolled ? Images.logoOnDark : Images.logoOnlight} alt="Vihara Logo" />
                    </a>
                </div>
                <div className='menu-icon'>
                    <FontAwesomeIcon onClick={handleClickonMenuIcon} icon={faBars} style={{ cursor: "pointer", color: scrolled ? "black" : "white", paddingRight: "10px", outline: "none" }} className={isScreenSmall ? "fa-lg" : 'fa-2xl'} />
                    <div className="mega-box" style={{ visibility: clickonMenuIcon ? "visible" : "hidden", opacity: "1" }}>
                        <div className="menu-icon-content">
                            <div style={{ display: "flex", justifyContent: "end", paddingRight: "10px", marginBottom: "30px", marginTop: "10px" }} >  <FontAwesomeIcon onClick={handleClickonMenuIcon} icon={faX} style={{ cursor: "pointer", color: "rgb(0 0 0 / 87%)", paddingRight: "10px" }} className="fa-lg" /></div>
                            <SimpleAccordion />
                            {user === null &&
                                <div className='small-screen-login-signup-button-container'>
                                    <a href="/login" className='small-login'>Sign in</a>
                                </div>}
                            {user === null &&
                                <div className='small-screen-navbar-register-link'>
                                    Don't have an account? <span onClick={() => window.location.href = "/sign-up"} > Register for free</span>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default SmallScreenNavbar
const SimpleAccordion = () => {
    const [expanded, setExpanded] = useState(false);
    const user = useSelector((state) => state.user.user);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    const getSummaryStyle = (panel) => ({
        color: expanded === panel ? 'rgb(3 132 251)' : 'rgb(0 0 0 / 87%)',
    });
    const handleLogout = () => {
        dispatch(clearUser());
        localStorage.removeItem("token");
        navigate("/login");
    };
    return (
        <div className='accordion-container-small-navbar'>
            <Accordion
                expanded={expanded === 'panel1'}
                onChange={handleChange('panel1')}
                style={{ backgroundColor: "aliceblue", boxShadow: "none" }}
            >
                <AccordionSummary
                    expandIcon={<ArrowDropDownIcon style={getSummaryStyle('panel1')} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={getSummaryStyle('panel1')}
                >
                    <div>Buy</div>
                </AccordionSummary>
                <AccordionDetails>
                    <div className="content">
                        <h2>Buy</h2>
                        <div className="buy-items">
                            <div className="buy-item-heading">
                                <a  onClick={() => window.location.href=`/auctions?Asset Type=${'Bank Owned'}`}> <p>REO Bank Owned</p></a>
                            </div>
                            {/* <div className="buy-item-text">
                                <p>Properties that are foreclosed and are owned by a bank</p>
                            </div> */}
                        </div>
                        <div className="buy-items">
                            <div className="buy-item-heading">
                                <a onClick={() => window.location.href = `/auctions?Asset Type=${'Short Sales'}`}>   <p> Short Sales</p></a>
                            </div>
                            {/* <div className="buy-item-text">
                                <p>Assets that are sold by homeowners</p>
                            </div> */}
                        </div>
                        <div className="buy-items">
                            <div className="buy-item-heading">
                                <a onClick={() => window.location.href = `/auctions?Asset Type=${'Foreclosure Homes'}`}> <p>Foreclosure Homes</p></a>
                            </div>
                            {/* <div className="buy-item-text">
                                <p>Properties that have been taken over by a lender</p>
                            </div> */}
                        </div>
                        <div className="buy-items">
                            <div className="buy-item-heading">
                                <a onClick={() => window.location.href = `/auctions`}> <p>Explore all Properties</p></a>
                            </div>
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>
            <Accordion
                expanded={expanded === 'panel2'}
                onChange={handleChange('panel2')}
                style={{ backgroundColor: "aliceblue", boxShadow: "none" }}
            >
                <AccordionSummary
                    expandIcon={<ArrowDropDownIcon style={getSummaryStyle('panel2')} />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                    style={getSummaryStyle('panel2')}
                >
                    <div>Calendar</div>
                </AccordionSummary>
                <AccordionDetails>
                    <div className='content'>
                        <h2>Calendar</h2>
                        <div className="resources-list">
                            <a href="/event-calender">
                                <p>Event Calendar</p>
                            </a>
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>
            <Accordion
                expanded={expanded === 'panel3'}
                onChange={handleChange('panel3')}
                style={{ backgroundColor: "aliceblue", boxShadow: "none" }}
            >
                <AccordionSummary
                    expandIcon={<ArrowDropDownIcon style={getSummaryStyle('panel3')} />}
                    aria-controls="panel3a-content"
                    id="panel3a-header"
                    style={getSummaryStyle('panel3')}
                >
                    <div>Resources</div>
                </AccordionSummary>
                <AccordionDetails>
                    <div className="content">
                        <h2>Resources</h2>
                        <div className="resources-list">
                            <a href="/blog">
                                <p>Blog</p>
                            </a>
                            <a href="/help">
                                <p>Help Center</p>
                            </a>
                            <a href="/buying_a_bank_owned">
                                <p>Buying a Foreclosure</p>
                            </a>
                            <a href="/buying_a_bank_owned">
                                <p>Buying a Bank Owned</p>
                            </a>
                            <a href="/glossary">
                                <p>Glossary</p>
                            </a>
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>
            <Accordion
                expanded={expanded === 'panel4'}
                onChange={handleChange('panel4')}
                style={{ backgroundColor: "aliceblue", boxShadow: "none" }}
            >
                <AccordionSummary
                    expandIcon={<ArrowDropDownIcon style={getSummaryStyle('panel4')} />}
                    aria-controls="panel4a-content"
                    id="panel4a-header"
                    style={getSummaryStyle('panel4')}
                >
                    <div>Company</div>
                </AccordionSummary>
                <AccordionDetails>
                    <div className="content">
                        <h2>Company</h2>
                        <div className="resources-list">
                            <a href="/about-us">
                                <p>About</p>
                            </a>
                            <a href="/career">
                                <p>Careers</p>
                            </a>
                            <a href="/diversity">
                                <p>Diversity</p>
                            </a>
                            <a href="/contact-us">
                                <p>Contact</p>
                            </a>
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>
            <Accordion
                expanded={expanded === 'panel5'}
                onChange={handleChange('panel5')}
                style={{ backgroundColor: "aliceblue", boxShadow: "none" }}
            >
                <AccordionSummary
                    expandIcon={<ArrowDropDownIcon style={getSummaryStyle('panel5')} />}
                    aria-controls="panel5a-content"
                    id="panel5a-header"
                    style={getSummaryStyle('panel5')}
                >
                    <div>Sell</div>
                </AccordionSummary>
                <AccordionDetails>
                    <div className="content">
                        <h2>Sell</h2>
                        <div className="resources-list">
                            <a href="/sell_properties">
                                <p>Sell your Property</p>
                            </a>
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>
            {user &&
                <Accordion
                    expanded={expanded === 'panel6'}
                    onChange={handleChange('panel6')}
                    style={{ backgroundColor: "aliceblue", boxShadow: "none" }}
                >
                    <AccordionSummary
                        expandIcon={<ArrowDropDownIcon style={getSummaryStyle('panel6')} />}
                        aria-controls="panel5a-content"
                        id="panel5a-header"
                        style={getSummaryStyle('panel6')}
                    >
                        <div>Dashboard</div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="content">
                            <h2>Dashboard</h2>
                            <div className="resources-list">
                                <a href="/portal/profile">
                                    <p>Profile</p>
                                </a>
                                <a href="/portal/bids">
                                    <p> My Bids</p>
                                </a>
                                <a href="/portal/purchase">
                                    <p> My Purchase Profiles</p>
                                </a>
                                <a href="/portal/offers">
                                    <p> My Offers</p>
                                </a>
                                <a href="/portal/savedSearches">
                                    <p> Saved Searches</p>
                                </a>
                                <a href="/portal/favorites">
                                    <p>  Favorites</p>
                                </a>
                                <a href="/portal/settings">
                                    <p> Account Settings</p>
                                </a>
                                <a href="/portal/alerts">
                                    <p> Alerts</p>
                                </a>
                                <a onClick={handleLogout}>
                                    <p>Sign out</p>
                                </a>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
            }

        </div>
    );
};

