import React, { useState } from 'react'
import { Helmet } from 'react-helmet';
import './buyingforcolsure.css'
import NavBackground from '../../includes/navBackground'
import CommonHeroSection from '../../HomePage/CommonHeroSection/CommonHeroSection'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faGavel, faFileAlt } from '@fortawesome/free-solid-svg-icons';
import Resources from '../../HomeComponents/Resources/Resources';
import CommonAcordianSection from '../Faqs/CommonAcordianSection';
import forcloseData from './forclosureFAQData';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { useNavigate } from 'react-router-dom';
import ForClosureVid from '../BuyingABankOwned/assets/bankowned.mp4'
import { PreAuctionData, AuctionDayData, PostAuctionData } from './LearnmoreContent';
import { useEffect } from 'react';
import { useMediaQuery } from '@mui/material';
const steps = [
    {
        number: 1,
        title: 'Create Vihara Account',
        description: 'When you create an account, you can search for properties, gain access to property information, and receive alerts on homes you’re interested in.'
    },
    {
        number: 2,
        title: 'Search Properties',
        description: 'Browse for properties near you on Vihara, and save the ones that you’re interested in to receive real-time alerts and notifications.'
    },
    {
        number: 3,
        title: 'Research before Bidding',
        description: 'Conduct your research on the property you’re interested in by reviewing the data on the property details page, estimating the value and renovation costs, and investigating the property'
    },
    {
        number: 4,
        title: 'Attend Auction',
        description: 'Get ready to attend the auction. Research the venue and check to confirm that the property is cleared for sale.'
    },
    {
        number: 5,
        title: 'Win Auction',
        description: 'Congrats on being the highest bid and winning the auction. Make sure you have government identification and pay for the property.'
    },
    {
        number: 6,
        title: 'Get Grant Deed',
        description: 'After purchasing the property, please contact the county as soon as possible to record the deed. Until you have your grant deed, do not enter the property.'
    },
    {
        number: 7,
        title: 'Gain Possession',
        description: 'Congrats, you have ownership of the property! Now you can renovate and update your property to increase your investment.'
    }
];



const states = [
    "Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado", "Connecticut", "Delaware", "Florida",
    "Georgia", "Hawaii", "Idaho", "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky", "Louisiana", "Maine",
    "Maryland", "Massachusetts", "Michigan", "Minnesota", "Mississippi", "Missouri", "Montana", "Nebraska",
    "Nevada", "New Hampshire", "New Jersey", "New Mexico", "New York", "North Carolina", "North Dakota",
    "Ohio", "Oklahoma", "Oregon", "Pennsylvania", "Rhode Island", "South Carolina", "South Dakota",
    "Tennessee", "Texas", "Utah", "Vermont", "Virginia", "Washington", "West Virginia", "Wisconsin", "Wyoming"
];


const LearnStateRequirement = () => {
    const isScreenSmall = useMediaQuery('(max-width:815px)');
    return (
        <section className="learn-state-requirement-section">
            <h2 className="learn-state-requirement-title">Learn your State Requirements</h2>
            <p className="learn-state-requirement-subtitle">
                This section offers a state-by-state guide on bidding in foreclosure auctions.
                Be sure to review the information for the state you're interested in before attending an auction.
            </p>
            {isScreenSmall ?
                <div>
                    <select className='select-state-input'>
                        <option>Select State</option>
                        {states.map((state, index) => {
                            return <option key={index}>{state}</option>
                        })}
                    </select>
                </div> :
                <div className="learn-state-requirement-states">
                    {states.map((state, index) => (
                        <a key={index} href="#" className="learn-state-requirement-state">
                            {state}
                        </a>
                    ))}
                </div>}
        </section>
    );
};



export const ForeclosureBiddingProcess = ({ steps, typeTitle, typeDesc }) => {
    const [activeStep, setActiveStep] = useState(null);
    const [isMobile, setIsMobile] = useState(false);

    const handleStepClick = (index) => {
        setActiveStep(index === activeStep ? null : index);
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768); // Adjust the breakpoint as needed
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const renderSteps = () =>
        steps.map((step, index) => (
            <div
                key={index}
                className={`foreclosure-bidding-process-step ${activeStep === index ? 'active' : ''}`}
                onClick={() => handleStepClick(index)}
            >
                <div className="foreclosure-bidding-process-step-number">{step.number}</div>
                <div className="foreclosure-bidding-process-step-title">{step.title}</div>
                <div className="foreclosure-bidding-process-step-description">{step.description}</div>
            </div>
        ));

    const renderCarouselItems = () =>
        steps.map((step, index) => (
            <div key={index} className="item" style={{ height: "200px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <div className={`foreclosure-bidding-process-step ${activeStep === index ? 'active' : ''}`}>
                    <div className="foreclosure-bidding-process-step-number">{step.number}</div>
                    <div className="foreclosure-bidding-process-step-title">{step.title}</div>
                    <div style={{ display: "block" }} className="foreclosure-bidding-process-step-description">{step.description}</div>
                </div>
            </div>
        ));

    return (
        <div className="foreclosure-bidding-main">
            <section className="foreclosure-bidding-process-section">
                <h2 className="foreclosure-bidding-process-title">{typeTitle}</h2>
                <p className="foreclosure-bidding-process-subtitle">{typeDesc}</p>
                <div className="foreclosure-bidding-process-steps">
                    {isMobile ? (
                        <AliceCarousel
                            mouseTracking
                            items={renderCarouselItems()}
                            autoPlayInterval={3000}
                            infinite
                            animationDuration={500}
                            disableDotsControls={false}
                            disableButtonsControls={false}
                        />
                    ) : (
                        renderSteps()
                    )}
                </div>
            </section>
        </div>
    );
};
export const ForeclosureSectionOne = () => {
    const navigate = useNavigate();
    const navigateWithData = (type, data) => {
        const params = new URLSearchParams({
            data: JSON.stringify(data)
        }).toString();
        window.location.href = `/learn-more/${type}?${params}`;
    };
    return (
        <section className="foreclosure-section-one-auction-section">
            <h2 className="foreclosure-section-one-title">Make bidding easier.</h2>
            <p className='foreclosure-bidding-process-subtitle'>Learn the steps for an auction before you bid.</p>
            <div className="foreclosure-section-one-cards">
                <div className="foreclosure-section-one-card">
                    <div className="foreclosure-section-one-icon-container">
                        <FontAwesomeIcon icon={faSearch} className="foreclosure-section-one-icon" />
                    </div>
                    <h3 className="foreclosure-section-one-card-title">Pre-Auction</h3>
                    <p className="foreclosure-section-one-card-text">
                        Before you bid in an auction, browse for properties, conduct your research, and gather your funds to be fully prepared for bidding.
                    </p>
                    <button onClick={() => navigateWithData('pre-auction', PreAuctionData)} className="foreclosure-section-one-learn-more">Learn More</button>
                </div>
                <div className="foreclosure-section-one-card">
                    <div className="foreclosure-section-one-icon-container">
                        <FontAwesomeIcon icon={faGavel} className="foreclosure-section-one-icon" />
                    </div>
                    <h3 className="foreclosure-section-one-card-title">Auction Day</h3>
                    <p className="foreclosure-section-one-card-text">
                        On the day of the auction, be prepared by checking the property status, register to bid for property, and bring your funds.
                    </p>
                    <button onClick={() => navigateWithData("auction-day", AuctionDayData)} className="foreclosure-section-one-learn-more">Learn More</button>
                </div>
                <div className="foreclosure-section-one-card">
                    <div className="foreclosure-section-one-icon-container">
                        <FontAwesomeIcon icon={faFileAlt} className="foreclosure-section-one-icon" />
                    </div>
                    <h3 className="foreclosure-section-one-card-title">Post-Auction</h3>
                    <p className="foreclosure-section-one-card-text">
                        After winning the auction, pay for the property and get the deed before gaining possession.
                    </p>
                    <button onClick={() => navigateWithData("post-auction", PostAuctionData)} className="foreclosure-section-one-learn-more">Learn More</button>
                </div>
            </div>
        </section>
    );
};

export const ForeclosureExperienceSection = ({ expTitle, expDesc, expButtonText, link }) => {
    const navigate = useNavigate();
    const handleClick = () => {
        window.scrollTo(0, 0);
        navigate(`/${link}`)
    }
    return (
        <section className="foreclosure-experience-section">
            <h2 className="foreclosure-experience-title">{expTitle}</h2>
            <p className="foreclosure-experience-subtitle">
                {expDesc}
            </p>
            <a href={`/${link}`} > <button className="foreclosure-experience-button">{expButtonText}</button></a>
        </section>
    );
};


export const ForeclosureFAQs = ({ forclosefaqData, faqTitle, faqDesc, faqButtontext }) => {
    return (
        <div>
            <section className="foreclosure-faqs-section">
                <hgroup className="foreclosure-faqs-hgroup">
                    <h2 className="foreclosure-faqs-heading">{faqTitle}</h2>
                    <h3 className="foreclosure-faqs-subheading">{faqDesc}</h3>
                </hgroup>
                {/* <p className="foreclosure-faqs-paragraph">
                    <button className="foreclosure-faqs-button" onClick={() => window.location.href = '/faqs'}>{faqButtontext}</button>
                </p> */}
            </section>
            <CommonAcordianSection
                width="80%"
                accData={forclosefaqData} />
        </div>
    )
}


export const CustomerResourceItem = ({ imgSrc, imgAlt, heading, description, linkText, linkHref }) => {
    return (
        <div className="customer-resource-item">
            <img className="customer-resource-icon" src={imgSrc} alt={imgAlt} />
            <h3 className="customer-resource-heading">{heading}</h3>
            <p
                className="customer-resource-description"
                dangerouslySetInnerHTML={{ __html: description }}
            />
            <a className="customer-resource-link" href={`/${linkHref}`} >{linkText} &#8250;</a>
        </div>
    );
};

export const ForclosureCustomerResource = () => {
    return (
        <div className="customer-resource-container">
            <h2 className="customer-resource-title">Customer Resources</h2>
            <p className="customer-resource-subtitle">
                Tap into our tools and resources before you start bidding.
            </p>
            <div className="customer-resource-grid">
                <CustomerResourceItem
                    imgSrc="https://images.ctfassets.net/bx9krvy0u3sx/4DcDo876RMHJbo2nACtHDD/74778bd8455cbb20d46fdd9f14a56dcb/Expert_Marketplace_or_Events.png?w=1534&fm=webp&q=80"
                    imgAlt="FAQs"
                    heading="FAQs"
                    description="See if your questions about auctions <br/> can be answered in our FAQ section"
                    linkText="Read FAQs"
                    linkHref="faqs"
                />
                {/* <CustomerResourceItem
            imgSrc="https://via.placeholder.com/50"
            imgAlt="Webinars & Trainings"
            heading="Webinars & Trainings"
            description="Join us for live product deep dives or stream trainings from our library of learning videos."
            linkText="See upcoming webinars"
            linkHref="#"
          /> */}
                {/* <CustomerResourceItem
            imgSrc="https://via.placeholder.com/50"
            imgAlt="Help Center"
            heading="Help Center"
            description="Browse onboarding and self-serve resources or connect with our support team."
            linkText="Get support"
            linkHref="#"
          /> */}
                {/* <CustomerResourceItem
            imgSrc="https://via.placeholder.com/50"
            imgAlt="Tips from Super Users"
            heading="Tips from Super Users"
            description="Hear from Samsara customers, partners, and experts to discover answers and spark new ideas."
            linkText="See tips"
            linkHref="#"
          /> */}
                <CustomerResourceItem
                    imgSrc="https://images.ctfassets.net/bx9krvy0u3sx/20bsuXSY6jjS5fDghYumuR/a313686e886808b23f4dce57fdd64d3c/Blog.png?w=1534&fm=webp&q=80"
                    imgAlt="Blog"
                    heading="Blog"
                    description="Read about latest industry <br/> trends and updates."
                    linkText="Read blog"
                    linkHref="blog"
                />
                <CustomerResourceItem
                    imgSrc="https://images.ctfassets.net/bx9krvy0u3sx/1k5Kr2ElpGRb4oBw97KgHS/07baf2c1caa540466aeee7fee9632666/icon_app_marketplace.png?w=1534&fm=webp&q=80"
                    imgAlt="Glossary"
                    heading="Glossary"
                    description="Browse through real estate <br/> terminology before you bid."
                    linkText="View glossary terms"
                    linkHref="glossary"
                />
            </div>
        </div>
    );
};


const BuyingAForclosure = () => {
    return (
        <div>
             <Helmet>
                <title>Guide to Buying Foreclosure Properties with Vihara AI</title>
                <meta name="description" content="Unlock the potential of buying foreclosure properties with Vihara AI's platform. Tips, strategies, and expert advice." />
                <meta name="keywords" content="Buying foreclosure, Vihara AI, real estate auctions, foreclosure tips, investment strategies" />
            </Helmet>
            <NavBackground />
            <CommonHeroSection
                title='Learn how to bid on a Foreclosure property.'
                description='Use this section to learn how you can prepare for foreclosure auctions before you bid.'
                videoSrc={ForClosureVid}
            />
            <ForeclosureSectionOne />
            <ForeclosureBiddingProcess
                steps={steps}
                typeTitle="The Foreclosure Bidding Process"
                typeDesc="Learn how to bid for foreclosure properties step-by-step."
            />
            <ForeclosureFAQs
                faqTitle="Still have questions?"
                faqDesc='Explore our FAQs below to see if we can help answer any questions you may have about foreclosure auctions.'
                faqButtontext='Go to FAQs'
                forclosefaqData={forcloseData}

            />
            <LearnStateRequirement />
            <ForclosureCustomerResource />
            <ForeclosureExperienceSection
                expTitle="Experience the future of real estate auctions."
                expDesc="We make bidding on foreclosure property auctions simpler and easier for you."
                expButtonText="Browse Homes"
                link="Foreclosure"
            />
        </div>
    )
}

export default BuyingAForclosure