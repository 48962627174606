import React from 'react';
import {
  AppBar,
  Toolbar,
  Button,
  IconButton
} from '@mui/material';
import MapIcon from '@mui/icons-material/Map';
import ListIcon from '@mui/icons-material/List';
import CustomDropdown from './DropDowns/CustomDropDown';
import { useFilterContext } from '../../../ContextProvider/Context';
import PropertyDropDown from './DropDowns/PropertyDown';
import ConditionDropDown from './DropDowns/ConditionDropDown';
import MoreFiltersDropdown from './DropDowns/MoreFilters';
import { useSearchParams } from 'react-router-dom';
const Navbar = ({ showMap, handleShowMap }) => {
  const {
    propertyType,
    setPropertyType,
    assetType,
    setAssetType,
    buyingType,
    setBuyingType,
    condition,
    setCondition,
    featured,
    setFeatured,
    moreFilters,
    setMoreFilters,
    handleClearFilters,
    handleSelectChange,
    setMinSquareFeet,
    setMaxSquareFeet,
    setBeds,
    setBaths
  } = useFilterContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const handleFilterChange = (filterType, value) => {
    // Update the searchParams with the new filter while keeping the old ones
    const newParams = new URLSearchParams(searchParams);

    if (value === 'all') {
      newParams.delete(filterType); // Remove filter if it's 'all'
    } else {
      newParams.set(filterType, value); // Add/Update the filter in the URL
    }

    setSearchParams(newParams); // Apply the updated search params to the URL
  };
  const handleClearFilterClick = () => {
    setSearchParams({});
    setPropertyType([]);
    setAssetType([]);
    setBuyingType([]);
    setCondition([]);
    setFeatured([]);
    setMoreFilters([]);
    setMinSquareFeet("");
    setMaxSquareFeet("");
    setBeds("Any");
    setBaths("Any");
  }
  return (
    <AppBar position="static" style={{ backgroundColor: "#f8f9fa" }} className='buy-Navbar-font'>
      <Toolbar>
        {/* Left side of the navbar */}
        <div style={{ flexGrow: 1, display: 'flex', alignItems: 'center', gap: '1rem' }}>
          <PropertyDropDown
            className='buy-Navbar-font'
            label="Property Type"
            options={['Single Family', 'Condo, Townhouse, other single unit', 'Multi-family', 'Land']}
            selectedValues={propertyType}
            onSelect={handleSelectChange(setPropertyType)}
          />
          {/* Adding text content at the top of the Asset Type dropdown */}
          <CustomDropdown
            className='buy-Navbar-font'
            label="Asset Type"
            options={['Bank Owned', 'Foreclosure Homes', 'Short Sale']}
            selectedValues={assetType}
            onSelect={handleSelectChange(setAssetType)}
          />

          {/* Adding a TextField as additional content for Buying Type */}
          <CustomDropdown
            className='buy-Navbar-font'
            label="Buying Type"
            options={['Online Auction', 'In Person Auction', 'Remote Bid', 'Direct Offer']}
            selectedValues={buyingType}
            onSelect={handleSelectChange(setBuyingType)}
          />

          <ConditionDropDown
            className='buy-Navbar-font'
            label="Condition"
            options={['Vacant', 'Interior Access Available', 'Eligible for Financing']}
            selectedValues={condition}
            onSelect={handleSelectChange(setCondition)}
          />

          {/* <CustomDropdown
            className='buy-Navbar-font'
            label="Featured"
            options={['Foreclosure Predict', 'Hot Foreclosure','Hot Bank Owned','Price Drop','$1 Opening Bid','Now Vacant']}
            selectedValues={featured}
            onSelect={handleSelectChange(setFeatured)}
          /> */}

          <MoreFiltersDropdown
            className='buy-Navbar-font'
            label="More Filters"
            options={['Hide Which Aution ends soon', 'Sold,Pending, or Canceled', 'Broker Co-op', 'Reserve Disclosed', 'No Buyer`s', 'First Look']}
            selectedValues={moreFilters}
            onSelect={handleSelectChange(setMoreFilters)}
          />


          <span className='buy-Navbar-font' onClick={handleClearFilterClick} style={{ color: "#000000DE", cursor: "pointer", fontSize: "16px", fontWeight: "300" }}>Clear Filters</span>
        </div>
        {/* Right side of the navbar */}
        <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
          <Button className='buy-Navbar-font' variant="contained" sx={{ backgroundColor: '#0384fb', color: 'white', fontWeight: 300 }}> Save Search</Button>
          <IconButton style={{ border: "1px solid #118BF4", borderRadius: "8px", height: "38px" }} color="inherit" onClick={handleShowMap}>
            <ListIcon style={{ color: showMap ? "#dde0e0" : "#007bff", marginRight: "5px", borderRadius: "5px" }} />  <MapIcon style={{ color: showMap ? "#007bff" : "#dde0e0", borderRadius: "5px" }} />
          </IconButton>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
