import React, { useState } from 'react';
import { Box, Modal, Typography, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from 'react-redux';
import { setUser } from '../../../features/user/userSlice';
import EmailOTPModel from './EmailOTPModel';
import './ChangeEmailModal.css';
import axios from 'axios';
const style = {
  position: 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 450,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const ChangeEmailModal = ({ open, handleClose,user }) => {
  const [newEmail, setNewEmail] = useState('');
  const [confirmEmail, setConfirmEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [confirmEmailError, setConfirmEmailError] = useState('');
  const [openOTPModel,setOpenOTPModel]=useState(false);
  const [OTP,setOTP]=useState('');
  const handleOpenOTPModel=()=>{
       setOpenOTPModel(true);
  }
  const handleCloseOTPModel=()=>{
       setOpenOTPModel(false);
  }
  // Email validation regex
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (e) => {
    setNewEmail(e.target.value);
    if (!e.target.value) {
      setEmailError('Email cannot be empty');
    } else if (!validateEmail(e.target.value)) {
      setEmailError('Please enter a valid email address');
    } else {
      setEmailError(''); // Clear error if valid
    }
  };

  const handleConfirmEmailChange = (e) => {
    setConfirmEmail(e.target.value);
    if (!e.target.value) {
      setConfirmEmailError('Confirm email cannot be empty');
    } else if (e.target.value !== newEmail) {
      setConfirmEmailError('Emails do not match');
    } else {
      setConfirmEmailError(''); // Clear error if valid
    }
  };
  const handleSubmit =async () => {
    //  handleClose();
    if (!newEmail || !confirmEmail) {
      if (!newEmail) setEmailError('Email cannot be empty');
      if (!confirmEmail) setConfirmEmailError('Confirm email cannot be empty');
    } else if (emailError || confirmEmailError) {
      // Errors already set, no need to submit
      return;
    } else {
      // Submit the form, assuming no errors
      try {
        const response = await axios.post(`https://viharabackend.onrender.com/api/v1/user/send-otp`, {email:newEmail});
         setOTP(response.data.otp);
         setOpenOTPModel(true);
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <>
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="change-email-title"
      aria-describedby="change-email-description"
    >
      <Box className="change-email-modal-box">
        <Box display="flex" justifyContent="space-between" alignItems="center" style={{ marginBottom: '20px' }}>
          <Typography id="change-email-title" variant="h6">
            Change Email
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <input
          className={`change-email-modal-input ${emailError ? 'change-email-modal-input-error' : ''}`}
          placeholder='New Email'
          value={newEmail}
          onChange={handleEmailChange}
        />
        {emailError && <div className="change-email-modal-error-text">{emailError}</div>}
        <input
          className={`change-email-modal-input ${confirmEmailError ? 'change-email-modal-input-error' : ''}`}
          placeholder='Confirm Email'
          value={confirmEmail}
          onChange={handleConfirmEmailChange}
        />
        {confirmEmailError && <div className="change-email-modal-error-text">{confirmEmailError}</div>}
        <button
          fullWidth
          variant='contained'
           className='change-email-modal-button'
          onClick={handleSubmit}
        >
          Update
        </button>
      </Box>
    </Modal>
    <EmailOTPModel  email={newEmail} userID={user._id} recievedotp={OTP} open={openOTPModel} onClose={handleCloseOTPModel} />
    </>
  );
};

export default ChangeEmailModal;
